import styles from "./SecInfra.module.css";
import {
  protocolData,
} from "../../../utils/reportDataTypes";
import { isNumber } from "../../../utils/adminUtils";
import { useState, useEffect } from "react";
import FormInput from "../../Reusable/FormInput";
import BasicList from "../../Reusable/BasicList";
import { addPastAuditDataURL } from "../../../api";
import {
  auditDataSubmittRequest,
  auditReportLink,
} from "../../../utils/severRequestTypes";
import { sendRequest } from "../../../utils/apiRequest";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import { useDataContext } from "../../../utils/dataProvider";
import CircularProgress from "../../Reusable/CircularProgress";

interface ISecInfra {
  protocolData: protocolData | null;
  setProgress: (newA: number[]) => void;
}

export const SecInfra: React.FC<ISecInfra> = ({
  protocolData,
  setProgress,
}) => {
  const { allAuditCompanies } = useDataContext();
  allAuditCompanies.sort((a, b) => a.name.localeCompare(b.name));

  const [uploadRequestSubmitted, setUploadRequestSubmitted] = useState<boolean>(false);

  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0]);
  const initialArray: auditReportLink[] = protocolData
    ? protocolData.pastAudits.audits.map(({ link, company,vulnSectionStart,vulnSectionEnd }) => ({
        auditReportLink: link,
        auditedBy: company,
        vulnSectionStart: vulnSectionStart,
        vulnSectionEnd: vulnSectionEnd,
      }))
    : [];
  const defaultAuditDataSubmittRequest : auditDataSubmittRequest = {
    protocolReportID: 0,
    pastAuditLinks: initialArray,
    secData: {
      bugBounty: "",
      bugBountyAmount: 0,
      paidWhiteHatBefore: "",
      auditCompanyIDs: [],
      recentSecIndicents: [],
    }
  }
  const [auditDataSubmittRequest, setAuditDataSubmittRequest] = useState<auditDataSubmittRequest>(defaultAuditDataSubmittRequest);

  useEffect(() => {
    const keyName = protocolData !== null ? `${protocolData?.rid}_SD` : `NEW_SD`;
    const savedRequest = localStorage.getItem(keyName);
    const localSecDataRequest : auditDataSubmittRequest = savedRequest !== null ? JSON.parse(savedRequest) : defaultAuditDataSubmittRequest;

    if(localSecDataRequest === null && protocolData === null){
      return;
    }
    if(protocolData === null){
      setAuditDataSubmittRequest(localSecDataRequest);
      return;
    }
    const apiData : auditDataSubmittRequest = {
      protocolReportID: protocolData.rid,
      secData: protocolData.secInfra,
      pastAuditLinks:initialArray
    }
    const mergedData : auditDataSubmittRequest = { ...localSecDataRequest, ...apiData };
    setAuditDataSubmittRequest(mergedData);
  }, [protocolData]);

  useEffect(() => {
    const keyName = protocolData !== null ? `${protocolData?.rid}_SD` : `NEW_SD` ;
    const savedRequest = localStorage.getItem(keyName);
    const localSecDataRequest : auditDataSubmittRequest = savedRequest !== null ? JSON.parse(savedRequest) : defaultAuditDataSubmittRequest;
    if(localSecDataRequest !== auditDataSubmittRequest && auditDataSubmittRequest !== defaultAuditDataSubmittRequest){
      localStorage.setItem(keyName, JSON.stringify(auditDataSubmittRequest));
    }
  }, [auditDataSubmittRequest]);

  const sendWritePastAuditDataRequest = async () => {
    setUploadRequestSubmitted(true);
    sendRequest(
      addPastAuditDataURL,
      "Something went wrong while write!",
      "Added Data!",
      auditDataSubmittRequest,
      () => {setUploadRequestSubmitted(false)}
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 4]);
    }
  };

  if (protocolData && protocolData.secInfra !== undefined) {
    if (protocolData.secInfra.bugBounty !== null) {
      if (protocolData.secInfra.bugBounty.length > 0) {
        setProgressHelper(1);
      }
    }
    if (protocolData.secInfra.bugBountyAmount !== null) {
      if (protocolData.secInfra.bugBountyAmount > 0) {
        setProgressHelper(2);
      }
    }
    if (protocolData.secInfra.paidWhiteHatBefore !== null) {
      if (protocolData.secInfra.paidWhiteHatBefore.length > 0) {
        setProgressHelper(3);
      }
    }
    if (protocolData.pastAudits !== null) {
      if (protocolData.pastAudits.audits !== null) {
        if (protocolData.pastAudits.audits.length > 0) {
          setProgressHelper(0);
        }
      }
    }
  }

  const handleAddAuditLink = () => {
    const arr2: auditReportLink[] = auditDataSubmittRequest.pastAuditLinks.concat([
      { auditReportLink: "", auditedBy: 0, vulnSectionStart: undefined, vulnSectionEnd: undefined },
    ]);
    auditDataSubmittRequest.pastAuditLinks = arr2;
    setAuditDataSubmittRequest({ ...auditDataSubmittRequest });

    setProgressHelper(0);
  };

  const handleAddAuditLinkChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      auditDataSubmittRequest.pastAuditLinks[index].auditReportLink = e.target.value;
      setAuditDataSubmittRequest({ ...auditDataSubmittRequest });
    };

  const handleRemoveAuditLink = (index: number) => {
    const arr2: auditReportLink[] = auditDataSubmittRequest.pastAuditLinks;
    arr2.splice(index, 1);
    auditDataSubmittRequest.pastAuditLinks = arr2;
    setAuditDataSubmittRequest({ ...auditDataSubmittRequest });
  };

  const handleBugBountyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    auditDataSubmittRequest.secData.bugBounty = e.target.value;
    setAuditDataSubmittRequest({ ...auditDataSubmittRequest });
    setProgressHelper(1);
  };

  const handleBugBountyAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let num = Number(e.target.value);
    if (isNumber(num)) {
      auditDataSubmittRequest.secData.bugBountyAmount = num;
      setAuditDataSubmittRequest({ ...auditDataSubmittRequest });
      setProgressHelper(2);
    }
  };

  const handlePaidWhiteHatBefore = (e: React.ChangeEvent<HTMLInputElement>) => {
    auditDataSubmittRequest.secData.paidWhiteHatBefore = e.target.value;
    setAuditDataSubmittRequest({ ...auditDataSubmittRequest });
    setProgressHelper(3);
  };

  const handleAuditCompanyChange = 
    (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      auditDataSubmittRequest.pastAuditLinks[index].auditedBy = Number(event.target.value);
      setAuditDataSubmittRequest({ ...auditDataSubmittRequest });
    };

  const handleVulnSectionStartChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedLinks = [...auditDataSubmittRequest.pastAuditLinks];
    updatedLinks[index].vulnSectionStart = Number(e.target.value);
    auditDataSubmittRequest.pastAuditLinks = updatedLinks;
    setAuditDataSubmittRequest({ ...auditDataSubmittRequest });
  };

  const handleVulnSectionEndChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedLinks = [...auditDataSubmittRequest.pastAuditLinks];
    updatedLinks[index].vulnSectionEnd = Number(e.target.value);
    auditDataSubmittRequest.pastAuditLinks = updatedLinks;
    setAuditDataSubmittRequest({ ...auditDataSubmittRequest });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <FormInput
            value={auditDataSubmittRequest.secData.bugBounty}
            placeholder={"https://..."}
            onChange={handleBugBountyChange}
            label="Bug bounty"
          />
          <FormInput
            value={auditDataSubmittRequest.secData.bugBountyAmount}
            placeholder={"15"}
            onChange={handleBugBountyAmountChange}
            label="Bug bounty amount in USD"
          />
          <FormInput
            value={auditDataSubmittRequest.secData.paidWhiteHatBefore}
            placeholder="Paid whitehats when..."
            onChange={handlePaidWhiteHatBefore}
            label="Paid whitehat before"
          />
        </div>
        <div className={styles.containerInner}>
          <BasicList
            gap={20}
            label="Audit Report Links"
            addButtonText="Audit Report"
            handleRemove={handleRemoveAuditLink}
            handleAdd={handleAddAuditLink}
            list={auditDataSubmittRequest.pastAuditLinks}
            items={[
              {
                type: "input",
                property: "auditReportLink",
                onChange: handleAddAuditLinkChange,
                label: "",
                placeholder: "https://",
                borderIsValid: [],
              },
              {
                type: "select",
                property: "auditedBy",
                onChange: handleAuditCompanyChange,
                label: "",
                display: "name",
                // value: auditReportLinksObj?.[0]?.auditedBy?.toString() || "",
                getValue: (i: number) => {
                  return auditDataSubmittRequest.pastAuditLinks[i]?.auditedBy?.toString() || "";
                },
                curValue: "id",
                options: allAuditCompanies.map((company) => ({
                  id: company.id,
                  name: company.name,
                })),
              },
              {
                type: "input",
                property: "vulnSectionStart",
                onChange: handleVulnSectionStartChange,
                label: "",
                placeholder: "Vulnerability Section Start (page/section)",
                borderIsValid: [],
              },
              {
                type: "input",
                property: "vulnSectionEnd",
                onChange: handleVulnSectionEndChange,
                label: "",
                placeholder: "Vulnerability Section End (page/section)",
                borderIsValid: [],
              },
            ]}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
          onClick={() => sendWritePastAuditDataRequest()}
          disabled={protocolData === null || uploadRequestSubmitted}
          style={{display:"flex",alignItems:"row",WebkitAlignItems:"center",justifyContent:"center",gap:"10px"}}
        >
          {uploadRequestSubmitted ? <CircularProgress /> : <></>}
          Upload Security Data
        </button>
      </div>
    </>
  );
};

import styles from "./FeaturedAuditCompany.module.css";
import BasicToolTip from "../Reusable/BasicToolTip";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import inputControlStyles from "../../styles/common/inputControls.module.css";
import LineChart from "./Graph";

const FeaturedAuditCompany = () => {
  return (
    <div className={styles.featureBox}>
      <h3>Start using SCAS Pro </h3>
      <div className={styles.featuredBoxInner}>
        <div className={styles.companyTypeRow}>
          <h4>Unlock All Features</h4>
          <p>Unlock more API calls, use exclusive pro features and more</p>
          <Link to={"/join/purchase"} className={inputControlStyles["button-link"]}>
            <button
              className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
            >
              Get Started
            </button>
          </Link>
        </div>
        <div className={styles.trustedBy}>
          <h4>
            SCAS in Numbers
            <BasicToolTip
              icon={<IoShieldCheckmarkOutline size={20} />}
              text={<p>SCAS Verified</p>}
            />
          </h4>
          <LineChart />
        </div>
      </div>
    </div>
  );
};
export default FeaturedAuditCompany;

import { useEffect, useState, useCallback } from "react";
import styles from "./AddProtocol.module.css";
import { BasicInfo } from "../../../components/PublishPageComponents/BasicInfo";
import CodeComplexity from "../../../components/PublishPageComponents/CodeComplexity";
import Centralization from "../../../components/PublishPageComponents/Centralization";
import { protocolData } from "../../../utils/reportDataTypes";
import { SecInfra } from "../../../components/PublishPageComponents/SecInfra";
import ScoreCalculation from "../../../components/PublishPageComponents/ScoreCalculation";
import SideMenuPublishTool from "../../../components/PublishPageComponents/SideMenu";
import Collapsible from "../../../components/Reusable/Collapsible";
import { sendRequest } from "../../../utils/apiRequest";
import {
  getReportUnderreviewDataURL,
  getReportUnderreviewDataAnyURL,
  getReportCreatedURL,
} from "../../../api";
import { useSearchParams } from "react-router-dom";
import { userData } from "../../../utils/severRequestTypes";
import Stepper from "./Stepper";
import { protocolState } from "../ProtocolList";
import inputControlStyles from "../../../styles/common/inputControls.module.css";

interface IAddProtocolPage {
  user: userData | null;
}

const AddProtocolPage: React.FC<IAddProtocolPage> = ({ user }) => {
  const [progressBasicInfo, setProgressBasicInfo] = useState<number[]>([0, 6]);

  const [progressSecInfra, setProgressSecInfra] = useState<number[]>([0, 4]);

  const [progressCodeComp, setProgressCodeComp] = useState<number[]>([0, 4]);

  const [progressCentraInfo, setProgressCentraInfo] = useState<number[]>([
    0, 5,
  ]);
  const [progressScoreCalc, setProgressScoreCalc] = useState<number[]>([0, 2]);

  const [queryParameters] = useSearchParams();
  const currentProtocolID: number | null =
    queryParameters.get("id") !== null
      ? Number.parseInt(queryParameters.get("id") ?? "")
      : null;

  const parsedState = Number.parseInt(queryParameters.get("state") ?? "-1");

  // Check if parsedState is a valid enum value
  const state = Object.values(protocolState).includes(parsedState)
    ? (parsedState as protocolState)
    : protocolState.Created;

  var URL = getReportUnderreviewDataAnyURL;
  if (state === protocolState.Underreview) {
    URL = user?.isAdmin
      ? getReportUnderreviewDataAnyURL
      : getReportUnderreviewDataURL;
  }
  if (state === protocolState.Created) {
    URL = user?.isAdmin ? getReportCreatedURL : getReportCreatedURL;
  }

  const [currentProtocolData, setCurrentProtocolData] =
    useState<protocolData | null>(null);

  const getCurrentProtocolData = useCallback(
    async (currentProtocolID: number) => {
      await sendRequest(
        URL,
        "Something went wrong while getting past reports!",
        undefined,
        currentProtocolID,
        setCurrentProtocolData
      );
    },
    [user?.isAdmin]
  );

  useEffect(() => {
    const fetchProtocolPreviews = async () => {
      if (currentProtocolID !== null) {
        await getCurrentProtocolData(currentProtocolID);
      }
    };
    fetchProtocolPreviews();
  }, [currentProtocolID, getCurrentProtocolData]);

  const activeStep =
    state === protocolState.Created
      ? 1
      : state === protocolState.Underreview
      ? 2
      : 3;
  const steps = [
    {
      number: 1,
      label: "Edit Report",
      isActive: state === protocolState.Created,
    },
    {
      number: 2,
      label: "Report is Reviewed",
      isActive: state === protocolState.Underreview,
    },
    {
      number: 3,
      label: "Published",
      isActive: state === protocolState.Published,
    },
  ];

  return (
    <>
      <div className={styles.protocol} id="adminContainer">
        <SideMenuPublishTool
          totalProgressMax={
            progressBasicInfo[1] +
            progressSecInfra[1] +
            progressCodeComp[1] +
            progressCentraInfo[1]
          }
          totalProgressCurrent={
            progressBasicInfo[0] +
            progressSecInfra[0] +
            progressCodeComp[0] +
            progressCentraInfo[0]
          }
          state={state}
          protocolPreview={currentProtocolData}
        />
        <div className={styles.protocol__content}>
          <div className={styles.protocol__header}>
            <Stepper steps={steps} currentStep={activeStep} />
          </div>
          <div className={styles.protocol__main}>
            {state === protocolState.Created ? (
              <>
                <Collapsible
                  title={"Add Basic Information"}
                  progress={progressBasicInfo}
                >
                  <BasicInfo
                    protocolData={currentProtocolData}
                    setCurrentProtocol={setCurrentProtocolData}
                    setProgress={setProgressBasicInfo}
                  />
                </Collapsible>
                <Collapsible
                  title={"Add Security Infrastructure Information"}
                  progress={progressSecInfra}
                >
                  <SecInfra
                    protocolData={currentProtocolData}
                    setProgress={setProgressSecInfra}
                  />
                </Collapsible>
                <Collapsible
                  title={"Add Code Base Information"}
                  progress={progressCodeComp}
                >
                  <CodeComplexity
                    protocolData={currentProtocolData}
                    setProgress={setProgressCodeComp}
                  />
                </Collapsible>
                <Collapsible
                  title={"Add Centralization Information"}
                  progress={progressCentraInfo}
                >
                  <Centralization
                    protocolData={currentProtocolData}
                    setProgress={setProgressCentraInfo}
                  />
                </Collapsible>
                <Collapsible
                  title={"Calculate Score"}
                  progress={progressScoreCalc}
                >
                  <ScoreCalculation
                    protocolReportID={
                      currentProtocolData !== null ? currentProtocolData.rid : 0
                    }
                    setProgress={setProgressScoreCalc}
                  />
                </Collapsible>
              </>
            ) : (
              <div className={styles.alertContainer}>
                <p>
                  <b>Report submitted for review </b><br /><br />
                  This report was submitted to review for scas. We will publish
                  it as soon as its reviewed. In case the report needs
                  additions, we will move it back to edit.
                  <br />
                  <br />
                  If you want to edit this yourself, please move back to edit.
                  But this will start the review process again!
                  <button
                    className={`${inputControlStyles.button} ${inputControlStyles["button--tertiary"]} ${inputControlStyles["button--small"]}`}
                    onClick={() => {}}
                  >
                    Move Report Back to edit
                  </button>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProtocolPage;

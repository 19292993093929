import { getSeverityBorderColor, getSeverityColor } from "../../utils/textformUtils";
import styles from "./VulnerabilityReport.module.css";
import { Link } from "react-router-dom";

interface IVulnerabilityReport {
  title: string;
  image: string;
  author: string;
  severity: string;
  link: string;
  ID: number;
}

const VulnerabilityReport: React.FC<IVulnerabilityReport> = ({
  title,
  image,
  author,
  severity,
  link,
  ID,
}) => {
  const pageLink = `/vulnerability?name=${link}`;
  return (
    <div className={styles.report}>
      <Link to={pageLink}>
        <div className={styles.titleContainer}>
          <img alt="" className={styles.logo} src={image} />
          <span className={styles.title}>{title}</span>
          <div
                style={{
                  background: getSeverityColor(severity),
                  borderColor: getSeverityBorderColor(severity),
                }}
                className={styles.vulLevel}
              >
                {severity.toLocaleUpperCase()}
              </div>
        </div>
      </Link>
    </div>
  );
};

export default VulnerabilityReport;

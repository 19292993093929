import styles from "./AuditCompaniesPage.module.css";
import { useState } from "react";
import SearchBar from "../../components/Reusable/SearchBar";
import { getType } from "../../utils/textformUtils";
import { useDataContext } from "../../utils/dataProvider";
import Pagination from "../../components/Reusable/Pagination";

const AuditCompaniesPage = () => {
  const { allAuditCompanies } = useDataContext();
  const [auditCompanySearch, setAuditCompanySearch] = useState("");
  const [buttonState, setButtonState] = useState(1);
  const itemsPerPage = 25;
  const handleAuditCompanySearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuditCompanySearch(e.target.value);
  };

  const filteredAuditCompanies =
    auditCompanySearch === ""
      ? allAuditCompanies.slice((buttonState - 1) * itemsPerPage, buttonState * itemsPerPage)
      : allAuditCompanies.filter((company) =>
          company.name
            .toLowerCase()
            .startsWith(auditCompanySearch.toLowerCase())
        );

  const handleButtonClick = (i: number) => {
    setAuditCompanySearch("");
    setButtonState(i);
  };
  return (
    <div className={styles.publications}>
      <div className={styles.container}>
        <div className={styles.reportContainer}>
          <h3>Smart Contract Audit Companies </h3>
          <p className={styles.infoText}>
            A currated list of the most important audit companies known to SCAS
          </p>
          <SearchBar
            value={auditCompanySearch}
            onChange={handleAuditCompanySearchChange}
            placeholder="Search for Audit Company"
          />

          <ul className={styles.CompanieList}>
            <li className={styles.row}>
              <p>Company</p>
              <p>Company Website</p>
              <p className={styles.companyType}>Company Type</p>
            </li>
            {filteredAuditCompanies.map((company) =>
              company.name !== "Other" ? (
                <li key={company.name} className={styles.row}>
                  <div className={styles.rowNameContainer}>
                    <img
                      alt=""
                      src={company.logoURL}
                      className={styles.image}
                    />
                    <p>{company.name}</p>
                  </div>
                  <a href={company.link}>Website</a>
                  <p className={styles.companyType}>{getType(company.type)}</p>
                </li>
              ) : (
                ""
              )
            )}
          </ul>
          {auditCompanySearch === "" && (
            <Pagination
              itemsPerPage={itemsPerPage}
              setCurrentPage={handleButtonClick}
              currentPage={buttonState}
              maxAmountOfEntries={allAuditCompanies.length}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuditCompaniesPage;

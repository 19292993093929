import styles from "./ScoreCalculation.module.css";
import { useEffect, useState } from "react";
import { deCentralizationScoreSubmittRequest } from "../../../utils/severRequestTypes";
import { addDeCentralizationScoreURL } from "../../../api";
import { sendRequest } from "../../../utils/apiRequest";
import FormSelect from "../../Reusable/FormSelect";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import CircularProgress from "../../Reusable/CircularProgress";

interface IScoreCalculation {
  protocolReportID: number;
  setProgress: (newA: number[]) => void;
}

export const ScoreCalculation: React.FC<IScoreCalculation> = ({
  protocolReportID,
  setProgress,
}) => {
  const [progress, setProgressLocal] = useState<number[]>([0, 0]);

  const defaultDeCentralizationScoreSubmittRequest : deCentralizationScoreSubmittRequest = {protocolReportID:0,canAdminStealFunds:false,canAdminIndirectlyStealFunds:false};
  const [scoreRequest,setScoreRequest] = useState<deCentralizationScoreSubmittRequest>(defaultDeCentralizationScoreSubmittRequest);
  const [uploadRequestSubmitted, setUploadRequestSubmitted] = useState<boolean>(false);

  const sendDeCentralizationScoreRequest = async () => {
    setUploadRequestSubmitted(true);
    sendRequest(
      addDeCentralizationScoreURL,
      "Something went wrong while write!",
      "Added Score!",
      scoreRequest,
      () => {setUploadRequestSubmitted(false)}
    );
  };

  useEffect(() => {
    const keyName = protocolReportID !== 0 ? `${protocolReportID}_CSD` : `NEW_CSD` ;
    const savedRequest = localStorage.getItem(keyName);
    const localRequest : deCentralizationScoreSubmittRequest = savedRequest !== null ? JSON.parse(savedRequest) : defaultDeCentralizationScoreSubmittRequest;

    if(localRequest === null && protocolReportID !== 0){
      return;
    }
    localRequest.protocolReportID = protocolReportID;
    setScoreRequest({...localRequest});
  }, [protocolReportID]);

  useEffect(() => {
    const keyName = protocolReportID !== 0 ? `${protocolReportID}_CSD` : `NEW_CSD` ;
    const savedRequest = localStorage.getItem(keyName);
    const localRequest : deCentralizationScoreSubmittRequest = savedRequest !== null ? JSON.parse(savedRequest) : defaultDeCentralizationScoreSubmittRequest;
    if(localRequest !== scoreRequest && scoreRequest !== defaultDeCentralizationScoreSubmittRequest){
      localStorage.setItem(keyName, JSON.stringify(scoreRequest));
    }
  }, [scoreRequest]);

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 2]);
    }
  };

  const handleCanAdminStealFundsChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const updatedObj = {...scoreRequest,canAdminStealFunds:Boolean(Number(e.target.value))};
    setScoreRequest(updatedObj);
    setProgressHelper(0);
  };

  const handleCanIndrectlyAdminStealFundsChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const updatedObj = {...scoreRequest,canAdminIndirectlyStealFunds:Boolean(Number(e.target.value))};
    setScoreRequest(updatedObj);
    setProgressHelper(1);
  };

  const adminStealFundsOptions = [
    { id: "1", name: "Yes, admin can directly steal funds" },
    { id: "0", name: "No, admin cannot directly steal funds" }
  ];

  const adminIndirectlyStealFundsOptions = [
    { id: "1", name: "Yes, admin can indirectly steal funds" },
    { id: "0", name: "No, admin cannot indirectly steal funds" }
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <FormSelect
            label="Can admin steal funds"
            value={(scoreRequest.canAdminStealFunds ? 1 : 0).toString()}
            display="name"
            curValue="id"
            onChange={handleCanAdminStealFundsChange}
            options={adminStealFundsOptions}
          />
        </div>
        <div className={styles.containerInner}>
          <FormSelect
            label="Can admin indirectly steal funds"
            value={(scoreRequest.canAdminIndirectlyStealFunds ? 1: 0).toString()}
            display="name"
            curValue="id"
            onChange={handleCanIndrectlyAdminStealFundsChange}
            options={adminIndirectlyStealFundsOptions}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
          onClick={() => sendDeCentralizationScoreRequest()}
          disabled={protocolReportID <= 0 || uploadRequestSubmitted}
          style={{display:"flex",alignItems:"row",WebkitAlignItems:"center",justifyContent:"center",gap:"10px"}}
        >
          {uploadRequestSubmitted ? <CircularProgress /> : <></>}
          Update DeCentralization Score
        </button>
      </div>
    </>
  );
};

export default ScoreCalculation;

import { useEffect, useState } from "react";
import { protocolDataCategory } from "../../../utils/protocolDataCategoryTypes";
import styles from "./BasicInfo.module.css";
import {
  isValidURL,
  checkIfValid
} from "../../../utils/adminUtils";
import BasicList from "../../Reusable/BasicList";
import FormSelect from "../../Reusable/FormSelect";
import {
  getBlockchainsURL,
  getReportUnderreviewDataURL,
  addBasicDataURL,
  getReportCreatedURL,
} from "../../../api";
import { basicData } from "../../../utils/severRequestTypes";
import { blockchain, protocolData } from "../../../utils/reportDataTypes";
import { sendRequest } from "../../../utils/apiRequest";
import FormTextArea from "../../Reusable/FormTextArea";
import FormInput from "../../Reusable/FormInput";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import CircularProgress from "../../Reusable/CircularProgress";

type protocolDataCategoryObject = {
  name: string;
  id: number;
};
interface IBasicInfo {
  protocolData: protocolData | null;
  setCurrentProtocol: (newP: protocolData) => void;
  setProgress: (newA: number[]) => void;
}
export const BasicInfo: React.FC<IBasicInfo> = ({
  protocolData,
  setCurrentProtocol,
  setProgress,
}) => {
  const [webURLIsValid, setWebURLIsValid] = useState<boolean>(
    protocolData !== null ? true : false,
  );
  const [uploadRequestSubmitted, setUploadRequestSubmitted] = useState<boolean>(false);
  const [blockchains, setBlockchains] = useState<blockchain[]>([]);
  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  const defaultBasicData = {
    protocolDataId: 0,
    name: "",
    websiteURL: "",
    deployedOnBlockchain: [],
    category:  protocolDataCategory.Other,
    recommendation: "",
  };
  const [basicDataRequest, setBasicDataRequest] = useState<basicData>(defaultBasicData);
  const protocolDataCategoryArrayString: string[] = Object.keys(
    protocolDataCategory,
  ).filter((key) => isNaN(Number(key)));

  const protocolDataCategoryArray: protocolDataCategoryObject[] = [];

  protocolDataCategoryArrayString.map((value, index) => {
    return protocolDataCategoryArray.push({ name: value, id: index });
  });

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 6]);
    }
  };

  if (protocolData && protocolData !== undefined) {
    if (protocolData.name.length > 0) {
      setProgressHelper(1);
    }
    if (protocolData.deployedOn.length > 0) {
      setProgressHelper(0);
    }
    if (protocolData.logoURL.length > 0) {
      setProgressHelper(2);
    }
    if (protocolData.category !== protocolDataCategory.Other) {
      setProgressHelper(5);
    }
    if (protocolData.recommendations.howToAvoidRisks.length > 0) {
      setProgressHelper(4);
    }
    if (protocolData.socialLinks.length > 0) {
      setProgressHelper(3);
    }
  }

  useEffect(() => {
    setWebURLIsValid(true);

    const keyName = protocolData !== null ? `${protocolData?.rid}_BD` : `NEW_BD`;
    const savedBasicData = localStorage.getItem(keyName);
    const localBasicdata : basicData = savedBasicData !== null ? JSON.parse(savedBasicData) : defaultBasicData;

    if(localBasicdata === null && protocolData === null){
      return;
    }
    if(protocolData === null){
      setBasicDataRequest(localBasicdata);
      return;
    }
    const apiData = {
      protocolDataId: protocolData.rid !== 0 ? protocolData?.rid : localBasicdata.protocolDataId,
      name: protocolData.name !== "" ? protocolData.name : localBasicdata.name,
      websiteURL: protocolData.socialLinks[0] !== "" ? protocolData.socialLinks[0] : localBasicdata.websiteURL,
      deployedOnBlockchain: protocolData.deployedOn.length > 0 ? protocolData.deployedOn : localBasicdata.deployedOnBlockchain,
      recommendation: protocolData.recommendations.howToAvoidRisks !== "" ? protocolData.recommendations.howToAvoidRisks : localBasicdata.recommendation,
    }
    const mergedData : basicData = { ...localBasicdata, ...apiData };
    setBasicDataRequest(mergedData);

  }, [protocolData]);

  useEffect(() => {
    const fetchData = async () => {
      await sendGetAllBlockchainsRequest();
    };
    if(blockchains.length === 0){
      fetchData();
    }
  }, []);
  useEffect(() => {
    const keyName = protocolData !== null ? `${protocolData?.rid}_BD` : `NEW_BD` ;
    const savedBasicData = localStorage.getItem(keyName);
    const localBasicdata : basicData = savedBasicData !== null ? JSON.parse(savedBasicData) : defaultBasicData;
    if(localBasicdata !== basicDataRequest && basicDataRequest !== defaultBasicData){
      localStorage.setItem(keyName, JSON.stringify(basicDataRequest));
    }
  }, [basicDataRequest]);

  const sendGetAllBlockchainsRequest = async () => {
    sendRequest(
      getBlockchainsURL,
      "Error getting audit companies",
      undefined,
      undefined,
      setBlockchains,
    );
  };

  const sendPublishReportDataRequest = async (data: basicData) => {
    const request: basicData = data;
    let newProtocolId: string | null = null;
    setUploadRequestSubmitted(true);
    await sendRequest(
      addBasicDataURL,
      "Something went wrong!",
      "Data added!",
      request,
      async (id) => {
        newProtocolId = id;
        setUploadRequestSubmitted(false);
        await sendRequest(
          getReportCreatedURL,
          "Something went wrong while getting past reports!",
          undefined,
          newProtocolId,
          setCurrentProtocol,
        );
      },
    );
  };

  const handleAddDeployedOn = () => {
    const updatedObj = {
      ...basicDataRequest,
      deployedOnBlockchain: basicDataRequest.deployedOnBlockchain.concat([0]),
    };
    setBasicDataRequest(updatedObj);
    setProgressHelper(0);
  };

  const handleDeployedOnChange =
    (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      basicDataRequest.deployedOnBlockchain[index] = Number(event.target.value);
      setBasicDataRequest({ ...basicDataRequest });
    };

  const handleRemoveDeployedOn = (indexToDel: number) => {
    const updatedObj = {
      ...basicDataRequest,
      deployedOnBlockchain: basicDataRequest.deployedOnBlockchain.filter(
        (_, index) => index !== indexToDel,
      ),
    };
    setBasicDataRequest({ ...updatedObj });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let copy = { ...basicDataRequest };
    copy.name = e.target.value;
    setBasicDataRequest(copy);
    setProgressHelper(1);
  };

  const handleWebUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidURL(e.target.value)) {
      setWebURLIsValid(true);
    } else {
      setWebURLIsValid(false);
    }

    let copy = { ...basicDataRequest };
    copy.websiteURL = e.target.value;
    setBasicDataRequest(copy);

    setProgressHelper(3);
  };

  const handleRecommendationsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    let copy = { ...basicDataRequest };
    copy.recommendation = e.target.value;
    setBasicDataRequest(copy);

    setProgressHelper(4);
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    basicDataRequest.category = Number(event.target.value);
    console.log(basicDataRequest.category);
    setBasicDataRequest({ ...basicDataRequest });
    setProgressHelper(5);
  };
  console.log(protocolDataCategory[basicDataRequest.category].toString());
  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <FormInput
            value={basicDataRequest.name}
            placeholder={"Uniswap"}
            onChange={handleNameChange}
            label="Name"
          />
          <FormInput
            value={basicDataRequest.websiteURL}
            placeholder={"www."}
            onChange={handleWebUrlChange}
            label="Website Url"
            // borderIsValid={[webURLIsValid]}
          />
          <FormTextArea
            label={"Recommendation on how to avoid risk"}
            value={basicDataRequest.recommendation}
            placeholder={"Use xyz...."}
            onChange={handleRecommendationsChange}
            // redBorder={false}
          />
          <FormSelect
            value={basicDataRequest.category.toString()}
            onChange={handleCategoryChange}
            label="Protocol Category"
            options={protocolDataCategoryArray}
            display="name"
            curValue="id"
          />
        </div>
        <div className={styles.containerInner}>
          <BasicList
            gap={20}
            label="Deployed on"
            addButtonText="Blockchain"
            handleRemove={handleRemoveDeployedOn}
            handleAdd={handleAddDeployedOn}
            list={basicDataRequest.deployedOnBlockchain}
            items={[
              {
                type: "select",
                onChange: handleDeployedOnChange,
                label: "",
                getValue: (i: number) => {
                  return basicDataRequest.deployedOnBlockchain[i]?.toString() || "";
                },
                display: "name",
                options: blockchains.map((blockchain) => ({
                  id: blockchain.chainId,
                  name: blockchain.name,
                })),
                curValue: "id",
              },
            ]}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
          onClick={() => sendPublishReportDataRequest(basicDataRequest)}
          disabled={!checkIfValid([webURLIsValid]) || uploadRequestSubmitted}
          style={{display:"flex",alignItems:"row",WebkitAlignItems:"center",justifyContent:"center",gap:"10px"}}
        >
          {uploadRequestSubmitted ? <CircularProgress /> : <></>}
          Upload Basic Data
        </button>
      </div>
    </>
  );
};

export default BasicInfo;

import styles from "./VulnerabilitesCarusel.module.css";
import {
  vulnerabilityReport,
  vulnerabilityReportList,
} from "../../../utils/vulnerabilityReportDataType";
import VulnerabilityReport from "../../../components/VulnerabilityReport";

interface IVulnerabilitesCarousel {
  allVulnerbilites: vulnerabilityReportList;
}
const VulnerabilitesCarousel: React.FC<IVulnerabilitesCarousel> = ({
  allVulnerbilites,
}) => {
  const reports = (vulnerbilitesSection: vulnerabilityReport[]) => {
    return (
      <>
        <div style={{ marginTop: "15px",display:"flex",gap:"15px" }}>
          {vulnerbilitesSection.slice(0, 3).map((report) => {
            return (
              <VulnerabilityReport
                ID={0}
                key={report.id}
                title={report.title}
                link={report.link}
                image={report.logoURL}
                author={report.company}
                severity={report.severity}
              />
            );
          })}
        </div>
        <div style={{ marginTop: "15px",display:"flex",gap:"15px" }}>
          {vulnerbilitesSection.slice(3, 6).map((report) => {
            return (
              <VulnerabilityReport
                ID={0}
                key={report.id}
                title={report.title}
                link={report.link}
                image={report.logoURL}
                author={report.company}
                severity={report.severity}
              />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={styles["Carousel_Container"]}>
      <div className={styles["Carousel_track"]}>
        <div style={{ marginTop: "15px" }} className={styles.reports}>
          {reports(allVulnerbilites.reports.slice(0, 6))}
        </div>
        <div style={{ marginTop: "15px" }} className={styles.reports}>
          {reports(allVulnerbilites.reports.slice(6, 12))}
        </div>
        <div style={{ marginTop: "15px" }} className={styles.reports}>
          {reports(allVulnerbilites.reports.slice(12, 18))}
        </div>
      </div>
    </div>
  );
};
export default VulnerabilitesCarousel;

import styles from "./CodeComplexity.module.css";
import BasicList from "../../Reusable/BasicList";
import { useEffect, useState } from "react";
import { codeDataSubmittRequest } from "../../../utils/severRequestTypes";
import { addCodeDataURL } from "../../../api";
import { sendRequest } from "../../../utils/apiRequest";
import { protocolData } from "../../../utils/reportDataTypes";
import FormInput from "../../Reusable/FormInput";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import CircularProgress from "../../Reusable/CircularProgress";

interface ICodeComplexity {
  protocolData: protocolData | null;
  setProgress: (newA: number[]) => void;
}

const CodeComplexity: React.FC<ICodeComplexity> = ({
  protocolData,
  setProgress,
}) => {
  const defaultCodeDataSubmittRequest : codeDataSubmittRequest = {
    reportID: 0,
    url: "",
    docsUrl: "",
    similarProtocols: [],
  }
  const [codeDataObj, setCodeDataObj] = useState<codeDataSubmittRequest>(defaultCodeDataSubmittRequest);
  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0]);
  const [uploadRequestSubmitted, setUploadRequestSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const keyName = protocolData !== null ? `${protocolData?.rid}_CCD` : `NEW_CCD` ;
    const savedRequest = localStorage.getItem(keyName);
    const savedData : codeDataSubmittRequest = savedRequest !== null ? JSON.parse(savedRequest) : defaultCodeDataSubmittRequest;

    if(savedData === null && protocolData === null){
      return;
    }
    if(protocolData === null){
      setCodeDataObj(savedData);
      return;
    }

    const apiData : codeDataSubmittRequest = {
      reportID: protocolData.rid,
      url: protocolData.codeBaseLink !== "" ? protocolData.codeBaseLink : savedData.url,
      docsUrl: protocolData.codeComplexity.docsUrl !== "" ? protocolData.codeComplexity.docsUrl : savedData.docsUrl,
      similarProtocols: protocolData.codeComplexity.similarProtocols.length > 1 ? protocolData.codeComplexity.similarProtocols : savedData.similarProtocols,
    }
    setCodeDataObj(apiData);
  }, [protocolData]);

  useEffect(() => {
    const keyName = protocolData !== null ? `${protocolData?.rid}_CCD` : `NEW_CCD` ;
    const savedRequest = localStorage.getItem(keyName);
    const savedData : codeDataSubmittRequest = savedRequest !== null ? JSON.parse(savedRequest) : defaultCodeDataSubmittRequest;
    if(savedData !== codeDataObj && codeDataObj !== defaultCodeDataSubmittRequest){
      localStorage.setItem(keyName, JSON.stringify(codeDataObj));
    }
  }, [codeDataObj]);

  const sendPublishReportDataRequest = async () => {
    setUploadRequestSubmitted(true);
    await sendRequest(
      addCodeDataURL,
      "Something went wrong while uploading data!",
      "Code added added!",
      codeDataObj,
      () => {setUploadRequestSubmitted(false)},
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 4]);
    }
  };
  if (protocolData && protocolData.codeComplexity !== undefined) {
    if (protocolData.codeBaseLink !== null) {
      if (protocolData.codeBaseLink.length > 0) {
        setProgressHelper(2);
      }
    }
    if (protocolData.codeComplexity.docsUrl !== null) {
      if (protocolData.codeComplexity.docsUrl.length > 0) {
        setProgressHelper(1);
      }
    }
    if (protocolData.codeComplexity.description !== null) {
      if (protocolData.codeComplexity.description.length > 0) {
        setProgressHelper(3);
      }
    }
    if (protocolData.codeComplexity.similarProtocols !== null) {
      if (protocolData.codeComplexity.similarProtocols.length > 0) {
        setProgressHelper(0);
      }
    }
  }
  const handleAddSimilar = () => {
    codeDataObj.similarProtocols.push({name:"",score:0});
    setCodeDataObj({ ...codeDataObj });
    setProgressHelper(0);
  };

  const handleSimilarChange =
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      codeDataObj.similarProtocols[i] = {name: e.target.value,score:1};
      setCodeDataObj({ ...codeDataObj });
    };

  const handleRemoveSimilar = (index: number) => {
    const arr2: any[] = codeDataObj.similarProtocols;
    arr2.splice(index, 1);
    codeDataObj.similarProtocols = arr2;
    setCodeDataObj({ ...codeDataObj });
  };

  const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    codeDataObj.url = e.target.value;
    setCodeDataObj({ ...codeDataObj });
    setProgressHelper(2);
  };

  const handleDocsURLChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    codeDataObj.docsUrl = e.target.value;
    setCodeDataObj({ ...codeDataObj });
    setProgressHelper(3);
  };

  return (
    <>
      <div className={styles.codeComplexityLayout}>
        <div className={styles.codeComplexityColumn}>
          <FormInput
          label={"Codebase Github URL"}
            placeholder="https://github..."
            value={codeDataObj.url}
            onChange={handleURLChange}
          />
          <FormInput
          label={"Codebase Docs URL"}
            placeholder="https://gitbook..."
            value={codeDataObj.docsUrl}
            onChange={handleDocsURLChange}
          />
        </div>
        <div className={styles.codeComplexityColumn}>
          <BasicList
            gap={10}
            label="Similar protocols"
            addButtonText="Protocol"
            handleRemove={handleRemoveSimilar}
            handleAdd={handleAddSimilar}
            list={codeDataObj.similarProtocols}
            items={[
              {
                type: "input",
                property: "name",
                onChange: handleSimilarChange,
                label: "",
                placeholder: "",
                borderIsValid: [],
              },
            ]}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
          onClick={() => sendPublishReportDataRequest()}
          disabled={protocolData === null || uploadRequestSubmitted}
          style={{display:"flex",alignItems:"row",WebkitAlignItems:"center",justifyContent:"center",gap:"10px"}}
        >
          {uploadRequestSubmitted ? <CircularProgress /> : <></>}
          Upload Code Data
        </button>
      </div>
    </>
  );
};

export default CodeComplexity;

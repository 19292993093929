import React from "react";
import { Line } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";

const LineChart: React.FC = () => {
  const data: ChartData<"line"> = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Reports",
        data: [65, 59, 80, 81, 56, 55],
        borderColor: "rgba(159, 122, 234, 1)",
        backgroundColor: "rgba(159, 122, 234, 0.2)",
        fill: true,
      },
      {
        label: "Vulnerabilites",
        data: [123, 320, 120, 150, 450, 500],
        borderColor: "#6e5ee2",
        backgroundColor: "rgba(159, 122, 234, 0.2)",
        fill: true,
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { 
        title: { display: true, text: "Months" }
      },
      y: { 
        title: { display: true, text: "Submissions" },
        beginAtZero: true
      },
    },
    plugins: {
        legend: {
          display: false
        }
      }
  };

  return (
    <div style={{height: "18rem",marginTop:"15px" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;

import styles from "./Centralization.module.css";
import { useEffect, useState } from "react";
import { centralizationDataSubmittRequest } from "../../../utils/severRequestTypes";
import { addCentralizationDataURL } from "../../../api";
import { sendRequest } from "../../../utils/apiRequest";
import { protocolData } from "../../../utils/reportDataTypes";
import FormInput from "../../Reusable/FormInput";
import FormSelect from "../../Reusable/FormSelect";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import CircularProgress from "../../Reusable/CircularProgress";

interface ICentralization {
  protocolData: protocolData | null;
  setProgress: (newA: number[]) => void;
}

const Centralization: React.FC<ICentralization> = ({
  protocolData,
  setProgress,
}) => {
  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0, 0]);
  const [uploadRequestSubmitted, setUploadRequestSubmitted] = useState<boolean>(false);
  const defaultCentralizationDataSubmittRequest = {
      reportID: 0,
      adminWallet: "",
      adminInformation: "",
      pausableInformation: "",
      timelockInformation: "",
      frontendDeployment: "",
      possibilityOfOwnFrontend: "",
      ownFrontendComplexityScore: 1,
  }
  const [centralizationDataSubmittRequest, setCentralizationDataSubmittRequest] = useState<centralizationDataSubmittRequest>(defaultCentralizationDataSubmittRequest);

  useEffect(() => {
    const keyName = protocolData !== null ? `${protocolData?.rid}_CD` : `NEW_CD` ;
    const savedRequest = localStorage.getItem(keyName);
    const localData : centralizationDataSubmittRequest = savedRequest !== null ? JSON.parse(savedRequest) : defaultCentralizationDataSubmittRequest;

    if(localData === null && protocolData === null){
      return;
    }
    if(protocolData === null){
      setCentralizationDataSubmittRequest(localData);
      return;
    }
    const apiData = {
      reportID: protocolData.rid,
      adminWallet:protocolData.centralization.adminWallet !== "" ? protocolData.centralization.adminWallet : localData.adminWallet,
      adminInformation:protocolData.centralization.adminInformation !== "" ? protocolData.centralization.adminInformation : localData.adminInformation,
      pausableInformation: protocolData.centralization.pausableInformation !== "" ? protocolData.centralization.pausableInformation : localData.pausableInformation,
      timelockInformation: protocolData.centralization.timelockInformation !== "" ? protocolData.centralization.timelockInformation : localData.timelockInformation,
      frontendDeployment: protocolData.centralization.frontendDeployment !== "" ? protocolData.centralization.frontendDeployment: localData.frontendDeployment,
      possibilityOfOwnFrontend: protocolData.centralization.possibilityOfOwnFrontend !== "" ? protocolData.centralization.possibilityOfOwnFrontend : localData.possibilityOfOwnFrontend,
      ownFrontendComplexityScore: protocolData.centralization.ownFrontendComplexityScore !== 0 ? protocolData.centralization.ownFrontendComplexityScore : localData.ownFrontendComplexityScore,
    }
    setCentralizationDataSubmittRequest(apiData);
  }, [protocolData]);

  useEffect(() => {
    const keyName = protocolData !== null ? `${protocolData?.rid}_CD` : `NEW_CD` ;
    const savedRequest = localStorage.getItem(keyName);
    const localData : centralizationDataSubmittRequest = savedRequest !== null ? JSON.parse(savedRequest) : defaultCentralizationDataSubmittRequest;
    if(localData !== centralizationDataSubmittRequest && centralizationDataSubmittRequest !== defaultCentralizationDataSubmittRequest){
      localStorage.setItem(keyName, JSON.stringify(centralizationDataSubmittRequest));
    }
  }, [centralizationDataSubmittRequest]);

  const sendPublishReportDataRequest = async () => {
    setUploadRequestSubmitted(true);
    sendRequest(
      addCentralizationDataURL,
      "Something went wrong while uploading data!",
      "Centralization added!",
      centralizationDataSubmittRequest,
      () => {setUploadRequestSubmitted(false)}
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 5]);
    }
  };
  if (protocolData && protocolData.centralization !== undefined) {
    if (protocolData.centralization.adminWallet.length > 0) {
      setProgressHelper(1);
    }
    if (protocolData.centralization.adminInformation.length > 0) {
      setProgressHelper(2);
    }
    if (protocolData.centralization.pausableInformation.length > 0) {
      setProgressHelper(0);
    }
    if (protocolData.centralization.frontendDeployment.length > 0) {
      setProgressHelper(3);
    }
    if (protocolData.centralization.possibilityOfOwnFrontend.length > 0) {
      setProgressHelper(4);
    }
  }
  const handleTimeLockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    centralizationDataSubmittRequest.timelockInformation = e.target.value;
    setCentralizationDataSubmittRequest({ ...centralizationDataSubmittRequest });
  };
  const handelAdminWalletChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    centralizationDataSubmittRequest.adminWallet = e.target.value;
    setCentralizationDataSubmittRequest({ ...centralizationDataSubmittRequest });
    setProgressHelper(1);
  };
  const handelAdminInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    centralizationDataSubmittRequest.adminInformation = e.target.value;
    setCentralizationDataSubmittRequest({ ...centralizationDataSubmittRequest });
    setProgressHelper(2);
  };
  const handelPauseInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    centralizationDataSubmittRequest.pausableInformation = e.target.value;
    setCentralizationDataSubmittRequest({ ...centralizationDataSubmittRequest });
    setProgressHelper(0);
  };
  const handelFrontendInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    centralizationDataSubmittRequest.frontendDeployment = e.target.value;
    setCentralizationDataSubmittRequest({ ...centralizationDataSubmittRequest });
    setProgressHelper(3);
  };
  const handelOwnFrontendInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    centralizationDataSubmittRequest.possibilityOfOwnFrontend = e.target.value;
    setCentralizationDataSubmittRequest({ ...centralizationDataSubmittRequest });
    setProgressHelper(4);
  };

  const handelOffChainScoreChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    centralizationDataSubmittRequest.ownFrontendComplexityScore = Number(e.target.value);
    setCentralizationDataSubmittRequest({ ...centralizationDataSubmittRequest });
  };

  const selectValues = [
    { name: "Excellent (1)", id: 1 },
    { name: "Average (2)", id: 2 },
    { name: "Poor (3)", id: 3 },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <FormInput
            value={centralizationDataSubmittRequest.timelockInformation}
            placeholder="Timelock on Stake..."
            onChange={handleTimeLockChange}
            label="Information about Timelocks"
          />
          <FormInput
            value={centralizationDataSubmittRequest.adminWallet}
            placeholder="0x..."
            onChange={handelAdminWalletChange}
            label="Admin wallet or multisig address"
          />
          <FormInput
            value={centralizationDataSubmittRequest.adminInformation}
            placeholder="Admin can ..."
            onChange={handelAdminInfoChange}
            label="Information about Admin"
          />
          <FormInput
            value={centralizationDataSubmittRequest.pausableInformation}
            placeholder="Can be paused.."
            onChange={handelPauseInfoChange}
            label="Information about Pause"
          />
        </div>
        <div className={styles.containerInner}>
          <FormInput
            value={centralizationDataSubmittRequest.frontendDeployment}
            placeholder="https://..."
            onChange={handelFrontendInformationChange}
            label="Frontend Link (App)"
          />
          <FormInput
            value={centralizationDataSubmittRequest.possibilityOfOwnFrontend}
            placeholder="Front end is possible..."
            onChange={handelOwnFrontendInformationChange}
            label="Information about 3rd party Frontend Deployment"
          />
          <FormSelect
            value={centralizationDataSubmittRequest.ownFrontendComplexityScore?.toString() || ""}
            onChange={handelOffChainScoreChange}
            label="How easy is it to maintain 3rd party Frontend?"
            options={selectValues}
            display="name"
            curValue="id"
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]} ${inputControlStyles["button--small"]}`}
          onClick={() => sendPublishReportDataRequest()}
          disabled={protocolData === null || uploadRequestSubmitted}
          style={{display:"flex",alignItems:"row",WebkitAlignItems:"center",justifyContent:"center",gap:"10px"}}
        >
          {uploadRequestSubmitted ? <CircularProgress /> : <></>}
          Upload Centralization Data
        </button>
      </div>
    </>
  );
};

export default Centralization;

import Report from "../../components/Report";
import styles from "./PublicationsPage.module.css";
import { getReportPreviewsURL, getVulnerabilityRangeURL } from "../../api";
import { useEffect, useState } from "react";
import { protocolPreview } from "../../utils/reportDataTypes";
import { Link } from "react-router-dom";
import { CiCircleMore } from "react-icons/ci";
import SearchBar from "../../components/Reusable/SearchBar";
import { darkTheme } from "../../utils/theme";
import FeaturedAuditCompany from "../../components/FeaturedAuditCompany";
import { sendRequest } from "../../utils/apiRequest";
import { vulnerabilityReportList } from "../../utils/vulnerabilityReportDataType";
import VulnerabilitesCarousel from "./VulnerabilitesCarousel";

interface IPublicationsPage {
  currentTheme?: typeof darkTheme;
  setCurrentProtocolID: (addProtocolActive: number) => void;
  setProtocolPreviews: (protocols: protocolPreview[]) => void;
  allprotocolsParam: protocolPreview[] | null;
}

const PublicationsPage: React.FC<IPublicationsPage> = ({
  setCurrentProtocolID,
  setProtocolPreviews,
  allprotocolsParam,
}) => {
  const [protocolSearch, setProtocolSearch] = useState("");
  const [allVulnerbilites, setAllVulnerbilites] = useState<
    vulnerabilityReportList | null
  >(null);
  const reportsPerRow = 5;

  const handleProtocolSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProtocolSearch(e.target.value);
  };

  useEffect(() => {
    const fetchProtocolPreviews = async () => {
      await sendRequest(
        getReportPreviewsURL,
        "Error getting Preview",
        undefined,
        undefined,
        setProtocolPreviews
      );
    };
    fetchProtocolPreviews();
  }, [setProtocolPreviews]);

  useEffect(() => {
    const fetchProtocolReport = async () => {
      await sendRequest(
        getVulnerabilityRangeURL,
        "Error while getting report",
        undefined,
        [0, 20],
        setAllVulnerbilites
      );
    };
    fetchProtocolReport();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.publications}>
        <div className={styles.reportContainer}>
          <h3>
            Top Security Scores{" "}
            <Link to={`protocols`} className={styles.moreLink}>
              view all <CiCircleMore />
            </Link>
          </h3>
          <SearchBar
            value={protocolSearch}
            onChange={handleProtocolSearchChange}
            placeholder="Search for protocol"
          />
          <div id="reports" className={styles.reports}>
            {allprotocolsParam ? (
              allprotocolsParam
                .sort((a, b) => b.score - a.score)
                .slice(0, reportsPerRow)
                .map((report) => (
                  <Report
                    ID={report.rid}
                    setCurrentProtocolID={setCurrentProtocolID}
                    key={report.rid}
                    title={report.name}
                    image={report.logoURL}
                    score={report.score}
                    audits={report.auditAmount}
                  />
                ))
            ) : (
              <></>
            )}
          </div>
          <h3 style={{ marginTop: "2rem" }}>
            Recently Added{" "}
            <Link to={`protocols`} className={styles.moreLink}>
              view all <CiCircleMore />
            </Link>
          </h3>
          <div
            id="reports"
            style={{ marginTop: "15px" }}
            className={styles.reports}
          >
            {allprotocolsParam ? (
              allprotocolsParam
                .reverse()
                .slice(0, reportsPerRow)
                .map((report) => (
                  <Report
                    ID={report.rid}
                    setCurrentProtocolID={setCurrentProtocolID}
                    key={report.rid}
                    title={report.name}
                    image={report.logoURL}
                    score={report.score}
                    audits={report.auditAmount}
                  />
                ))
            ) : (
              <></>
            )}
          </div>
          <h3 style={{ marginTop: "2rem" }}>
            Explore Vulnerabilites{" "}
            <Link to={`protocols`} className={styles.moreLink}>
              view all <CiCircleMore />
            </Link>
          </h3>
          {allVulnerbilites ? <VulnerabilitesCarousel allVulnerbilites={allVulnerbilites} /> : <></>}
        </div>
        <FeaturedAuditCompany />
      </div>
    </div>
  );
};

export default PublicationsPage;

import styles from "./Pagination.module.css";

interface IPagination {
    setCurrentPage: (page: number) => void;
    maxAmountOfEntries: number;
    currentPage: number;
    itemsPerPage: number;
}

const Pagination: React.FC<IPagination> = ({setCurrentPage,currentPage,maxAmountOfEntries, itemsPerPage }) => {

  return (
    <div className={styles.buttonsContainer}>
      {(() => {
        const totalPages =
          Math.floor(
            (Math.max(0, maxAmountOfEntries) - 1) /
              itemsPerPage
          ) + 1;
        const maxVisiblePages = 6;
        const firstPage = 1;
        const lastPage = totalPages;

        let startPage = Math.max(
          firstPage + 1,
          Math.min(
            currentPage - Math.floor(maxVisiblePages / 2),
            totalPages - maxVisiblePages
          )
        );
        let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages - 1);

        if (endPage - startPage < maxVisiblePages - 1) {
          startPage = Math.max(firstPage + 1, endPage - maxVisiblePages + 1);
        }

        const pages = [];

        // First Page
        if (totalPages > 1) pages.push(firstPage);

        // Middle Pages
        if (startPage > firstPage + 1) pages.push("...");
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
        if (endPage < lastPage - 1) pages.push("...");

        // Last Page
        if (totalPages > 1) pages.push(lastPage);

        return pages.map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === "number" && setCurrentPage(page)}
            style={currentPage === page ? { color: "#6E5EE2" } : {}}
            disabled={page === "..."}
          >
            {page}
          </button>
        ));
      })()}
    </div>
  );
};

export default Pagination;

const baseURL = `https://api.scauditstudio-api.de`;

export const productURL = `$${baseURL}/products`;

//Report data URLs
export const getReportDataURL = `${baseURL}/report/published/get`;
export const publishReportURL = `${baseURL}/report/publish`;
export const submitReportURL = `${baseURL}/report/submit`;
export const getReportCreatedURL = `${baseURL}/report/get`;

export const getReportPreviewsURL = `${baseURL}/report/preview/published/all`;
export const getReportPreviewsFilteredURL = `${baseURL}/report/preview/published/filter`;
export const getReportPreviewsPublishedByUserURL = `${baseURL}/report/preview/published/get`;

export const getReportUnderreviewDataURL = `${baseURL}/report/underreview/get`;

export const getReportPreviewsEditedURL = `${baseURL}/report/preview/get`;
//Allows Admin to read any Report under review
export const getReportUnderreviewDataAnyURL = `${baseURL}/report/underreview/any`;

export const getReportPreviewsFromUserUnderreviewURL = `${baseURL}/report/preview/underreview/all`;
export const getReportPreviewsFromAllUnderreviewURL = `${baseURL}/report/preview/underreview/every`;

//Audit Companie URLs
export const getAuditCompaniesURL = `${baseURL}/auditcompany/all`;
export const addAuditCompanyURL = `${baseURL}/auditcompany/add`;
export const getAuditCompanyURL = `${baseURL}/auditcompany/get`;
export const getAuditCompaniesByArrayURL = `${baseURL}/auditcompany/range`;

//Blockchain
export const addBlockchainURL = `${baseURL}/blockchain/add`;
export const getBlockchainsURL = `${baseURL}/blockchain/all`;

//VulnerabilityReport
export const getVulnerabilityURL = `${baseURL}/vulnerability/get`;
export const getVulnerabilityRangeURL = `${baseURL}/vulnerability/range`;

//User
export const getUserDataURL = `${baseURL}/account/data`;
export const verifyCodeURL = `${baseURL}/signup/validate`;
export const loginURL = `${baseURL}/signin`;
export const registerUserURL = `${baseURL}/signup`;
export const accountRecoverURL = `${baseURL}/account/recover`;
export const accountRecoverValidateURL = `${baseURL}/account/recover/validate`;

//Submitt Report Data
export const addBasicDataURL = `${baseURL}/report/basicdata/add`;
export const addDeCentralizationScoreURL = `${baseURL}/report/decscore/add`;
export const addSecurityInfraDataURL = `${baseURL}/report/secinfra/add`;
export const addPastAuditDataURL = `${baseURL}/report/pastaudits/add`;
export const addCentralizationDataURL = `${baseURL}/report/centralization/add`;
export const addCodeDataURL = `${baseURL}/report/codedata/add`;

//Delete Data
export const deleteCreatedReportURL = `${baseURL}/report/delete`;
export const deleteUnderreviewReportURL = `${baseURL}/report/underreview/delete`;
export const deletePublishedReportURL = `${baseURL}/report/published/delete`;

//External
export const getRequestCustomReportURL = `https://forms.gle/mH9pF82faBKhmCUy6`;
export const reportFeedbackURL = `https://forms.gle/dtvPsckAiwUx622S7`;

export const centralizationDocsURL = `https://scas.gitbook.io/scas-metrics/centralization-score`;
export const ratingDocsURL = `https://scas.gitbook.io/scas-metrics`;
export const otherDocsURL = `https://scas.gitbook.io/scas-metrics/other-scores`;
